import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/AccountView.vue')
  },
  {
    path: '/coins/:coin',
    name: 'coin',
    component: () => import('../views/CoinView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
